import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function LogoCloud() {
  return (
    <div className="bg-gray-100 shadow-inner">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
          Trusted by some impressive organizations
        </p>
        <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-4">
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <StaticImage
              src="../images/clients/upmc.svg"
              alt="UPMC"
              width={150}
              objectFit="contain"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <StaticImage
              src="../images/clients/pgh.png"
              alt="City of Pittsburgh"
              width={150}
              objectFit="contain"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <StaticImage
              src="../images/clients/hire-an-esquire.png"
              alt="Hire an Esquire"
              width={150}
              objectFit="contain"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <StaticImage
              src="../images/clients/now-an-then.png"
              alt="Now an Then"
              width={150}
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
