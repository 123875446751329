import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import useOnScreen from "../utils/useOnScreen";

const metrics = [
  {
    key: "value",
    staticStat: (<span>$4M+</span>),
    stat: (<span>$<span className="grow" data-num="4"></span>M+</span>),
    emphasis: "Value added",
    rest: "to our clients.",
  },
  {
    key: "hours",
    staticStat: (<span>24k+</span>),
    stat: (<span><span className="grow" data-num="24"></span>k+</span>),
    emphasis: "Hours saved",
    rest: "with thoughtful preview environments.",
  },
  {
    key: "books",
    staticStat: (<span>314+</span>),
    stat: (<span><span className="grow" data-num="314"></span>+</span>),
    emphasis: "Books read",
    rest: "with client team members.",
  },
  {
    key: "pizzas",
    staticStat: (<span>83+</span>),
    stat: (<span><span className="grow" data-num="83"></span>+</span>),
    emphasis: "Pizza's delivered",
    rest: "during internal training exercises.",
  },
];

export default function ClientMetrics() {
  const metricsRef = React.useRef();
  const isVisible = useOnScreen(metricsRef);
  if (isVisible) {
    setTimeout(() => {
      document.querySelectorAll('span.grow').forEach((counter) => { counter.style.setProperty("--num", parseInt(counter.dataset.num, 10))})
    }, 100)
  }

  return (
    <div className="relative bg-gray-900">
      <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <StaticImage
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src="../images/meeting-techies.jpeg"
              alt="People meeting with computers"
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold tracking-wide uppercase">
            <span className="bg-gradient-to-r from-blue-300 to-purple-200 bg-clip-text text-transparent">
              Client Metrics
            </span>
          </h2>
          <p className="mt-3 text-3xl font-extrabold text-white">
            Get actionable advice that will help grow your business
          </p>
          <p className="mt-5 text-lg text-gray-300">
            Metrics are an important part of strategy.
            We believe qualitative measures inform quantitative metrics.
            We help guard against surrogation while building your teams emergent mindset.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2" ref={metricsRef}>
            {metrics.map((item) => (
              <p key={item.key}>
                <span className="md:hidden block text-2xl font-bold text-white">
                  {item.staticStat}
                </span>
                <span className="hidden md:flex block text-2xl font-bold text-white">
                  {item.stat}
                </span>
                <span className="mt-1 block text-base text-gray-300">
                  <span className="font-medium text-white">
                    {item.emphasis}
                  </span>{" "}
                  {item.rest}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
