import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import ContactForm from "../components/contact";
import LogoCloud from "../components/logos";
import ClientMetrics from "../components/metrics";
import useOnScreen from "../utils/useOnScreen";

const IndexPage = () => {
  const moneyHighlight = React.useRef();

  const isVisible = useOnScreen(moneyHighlight);

  if (isVisible) {
    setTimeout(() => {
      document
        .querySelector("mark")
        .style.setProperty("--mark-background-position", "left");
    }, 1000);
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hyperfluid Solutions</title>
        <script type="text/javascript">{`
          (function (d, u, h, s) {
            h = d.getElementsByTagName('head')[0];
            s = d.createElement('script');
            s.async = 1;
            s.src = u + new Date().getTime();
            h.appendChild(s);
          })(document, 'https://grow.clearbitjs.com/api/pixel.js?v=');
          `}</script>
      </Helmet>
      <div className="relative bg-gray-50 overflow-hidden">
        <div
          className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
          aria-hidden="true"
        >
          <div className="relative h-full max-w-7xl mx-auto">
            <svg
              className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
              />
            </svg>
          </div>
        </div>

        <Header></Header>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mt-8 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6">
            <div className="text-center font-sans-serif">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block p-2 xl:inline">Our contracts</span>{" "}
                <mark className="inline text-purple-800" ref={moneyHighlight}>
                  make you faster
                </mark>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                We do software delivery for regulated industry. We do that by shifting left,
                shifting right, right-sizing, and organizing your software
                delivery strategy. We do it all while with uncompromised adherence to your security and privacy constraints.
              </p>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <a
                    href="/contact"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-800 hover:bg-purple-900 md:py-4 md:text-lg md:px-10"
                  >
                    Let's get started
                  </a>
                </div>
                {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-800 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                  >
                    Live demo
                  </a>
                </div> */}
              </div>
            </div>
          </main>
        </div>
      </div>
      <LogoCloud />
      <ClientMetrics />
      <div className="bg-gray-100 shadow-inner">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <ContactForm id="contact" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;
